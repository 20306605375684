<template lang="pug">
footer.main-footer
  .main-footer--socal-bar
    .social-text Volg ons op social
    a.social-link(v-for="social in socials" :href="social.link" target="_blank")
      i.fab(:class="social.icon")
  .main-footer--top
    .main-footer--top-left
      template(
        v-if="!$auth.user || !$auth.user.newsletter"
      )
        h4.main-footer--top-formtitle Krijg toegang tot exclusieve aanbiedingen en inspiratie van Hofweb
        button.button.gold.high.main-footer--top-formbutton(
          @click="formPopup = !formPopup"
        )
          | Ja, zet mij op de maillijst!
    ul.main-footer--top-list(v-for="(list, listIndex) in lists" :key="listIndex" :class="{ 'collapsed': list.collapsed }")
      h4.main-footer--top-title(@click="list.collapsed = !list.collapsed") {{ list.title }}
      li.main-footer--top-list--item(v-for="(item, itemIndex) in list.items" :key="itemIndex")
        nuxt-link.main-footer--top-list--item-link(:to="item.link") {{ item.title }}
  .main-footer--logos
    .main-footer--logos-wrapper
      figure.main-footer--logos-logo--wrapper.picture(v-for="(logo, logoIndex) in logos" :key="logoIndex")
        picture
          source(v-for="set in logo.srcSets" :srcset="set.src" :type="set.type")
          img.main-footer--logos-logo.lazyload(:src="logo.fallback" :style="logoStyle(logo)")
  .main-footer--bottom
    .main-footer--bottom-socialwrapper
      img.main-footer--bottom-logo(:src="require('~/assets/images/hofweb-footer-logo.png')")
      .main-footer--bottom-link.copyright-desktop &copy; {{ copyright }}
    .main-footer--bottom-linkwrapper
      img.main-footer--bottom-logo(:src="require('~/assets/images/footer-logos/nix-18.svg')")
      nuxt-link.main-footer--bottom-link(to="/algemene-voorwaarden") Algemene voorwaarden
      nuxt-link.main-footer--bottom-link(to="/privacy") Privacy
    .main-footer--bottom-linkwrapper.copyright-mobile
      .main-footer--bottom-link &copy; {{ copyright }}
  modal(
    v-model="formPopup"
    :primary-button="false"
    :secondary-button="false"
    :border="false"
    :padding="false"
    size="small-medium"
    position="top"
  )
    template(v-slot:title) Schrijf je in voor onze nieuwsbrief
    template(v-slot:body)
      form.main-footer--top-form(@submit.prevent="subscribeUser")
        .page-content
          p
            | Elke week recepten en inspiratie via e-mail? Wil je op de hoogte blijven van de biologische aanbiedingen
            | van het seizoen? Meld je aan en wij sturen je 1 keer per week een heerlijke nieuwsbrief.
        .main-footer--top-form--input-group
          form-input.main-footer--top-form--input(
            v-model="firstname"
            placeholder="Voornaam"
            :invalid="!!formError('firstname')"
            :validation-message="formError('firstname')"
            @input="unsetFormError('firstname')"
          )
          form-input.main-footer--top-form--input(
            v-model="lastname"
            placeholder="Achternaam"
            :invalid="!!formError('lastname')"
            :validation-message="formError('lastname')"
            @input="unsetFormError('lastname')"
          )
        .main-footer--top-form--input-group
          form-input.main-footer--top-form--input(
            v-model="email"
            placeholder="E-mailadres"
            :invalid="!!formError('email')"
            :validation-message="formError('email')"
            @input="unsetFormError('email')"
          )
        button.button.main-footer--top-form--submit-button(type="submit" :disabled="submitting")
          i.far(:class="{ 'fa-angle-right': !submitting, 'fa-circle-notch fa-spin': submitting }")
          span Inschrijven
</template>

<script>
import { mapGetters } from 'vuex';
import { enhancedEcommerce } from '@/mixins/enhancedEcommerce';

export default {
  name: 'MainFooter',
  components: {
    'form-input': () => import('@/components/form/formInput'),
    modal: () => import('@/components/modal'),
  },
  mixins: [enhancedEcommerce],
  props: {
    lists: {
      type: Array,
      default: () => [],
    },
    logos: {
      type: Array,
      default: () => [],
    },
    socials: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      firstname: '',
      lastname: '',
      email: '',
      submitting: false,
      formPopup: false,
      copyright: `${this.$moment(new Date()).format('YYYY')}, Hofweb. Alle rechten voorbehouden.`,
    };
  },
  computed: {
    ...mapGetters({
      errors: 'api/validationErrors',
    }),
  },
  watch: {
    formPopup(value) {
      if (value && this.$auth.loggedIn) {
        this.firstname = this.$auth?.user?.firstname;
        this.lastname = this.$auth?.user?.lastname;
        this.email = this.$auth?.user?.email;
      } else {
        this.firstname = '';
        this.lastname = '';
        this.email = '';
      }
    },
  },
  methods: {
    logoStyle(logo) {
      return logo.maxWidth ? { maxWidth: `${logo.maxWidth}px` } : {};
    },
    formError(key) {
      const error = this.errors ? this.errors[key] : false;

      if (error) {
        const [keyState] = error;

        return keyState;
      }

      return '';
    },
    unsetFormError(key) {
      this.$store.dispatch('api/unsetValidationError', { key });
    },
    async subscribeUser() {
      this.submitting = true;
      await this.$store.dispatch('api/subscribeNewsletter', {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
      });

      if (!Object.keys(this.errors || {}).length) {
        this.$notification({ message: 'Uw nieuwsbrief aanmelding is verstuurd.', type: 'success' });
        this.gtmPush({
          event: 'newsletter',
          eventCategory: 'nieuwsbrief',
          eventAction: 'aanmelden',
        });
      }

      this.formPopup = false;
      this.submitting = false;
    },
  },
};
</script>

<style lang="scss">
.main-footer {
  position: relative;
  background: $phthaloGreen;

  &--socal-bar {
    position: absolute;
    top: -70px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    background-image: url('~assets/images/grudges/grudge-dark.png');
    background-position: center;
    background-size: 100% 80px;

    .social-text {
      padding: 0 20px 0 0;
      color: $white;
      font-weight: 500;
      font-size: 18px;
    }

    .social-link {
      padding: 0 15px;
      color: $white;
      font-size: 18px;
    }
  }

  &--top {
    display: flex;
    max-width: 1300px;
    margin: 0 auto;
    padding: 70px 70px 0 70px;

    &-left {
      width: 50%;
      padding: 0 100px 0 0;
      box-sizing: border-box;
    }

    &-formtitle {
      max-width: 750px;
      margin: 0 0 30px 0;
      color: $darkSeaGreen;
      font-weight: 400;
      font-size: 35px;
      line-height: 43px;
      font-family: $fontBodoni;
    }

    &-title {
      margin: 0 0 20px 0;
      color: $white;
      font-weight: 500;
      font-size: 11px;
      line-height: 24px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    &-list {
      display: inline-block;
      width: max-content;
      margin: 5px 0 0 0;
      padding: 0 0 0 50px;
      list-style-type: none;
      font-size: 15px;
      line-height: 20px;
      box-sizing: border-box;
      width: 25%;

      &--item {
        padding-bottom: 12px;
        white-space: nowrap;

        &-link {
          color: $celadon;
          font-weight: 400;
          opacity: .6;
        }
      }
    }

    &-form {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      padding: 0 20px 20px 20px;

      &--input-group {
        display: flex;
      }

      &--input {
        width: 100%;
        margin-bottom: 10px;

        &:first-child {
          margin-right: 5px;
        }

        &:last-child {
          margin-left: 5px;
        }

        &:first-child:last-child {
          margin-right: 0;
          margin-left: 0;
        }

        &-group {
          width: 100%;
        }
      }
    }
  }

  &--logos {
    height: 180px;
    margin: 35px 0;
    padding: 25px 70px;

    &-wrapper {
      display: flex;
      justify-content: space-between;
      max-width: 1300px;
      height: 100%;
      margin: 0 auto;
    }

    &-logo {
      align-self: center;
      max-width: 150px;
      opacity: .75;

      &--wrapper {
        display: flex;
        align-items: center;
      }
    }
  }

  &--bottom {
    display: flex;
    justify-content: space-between;
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 70px 35px 70px;

    &-socialwrapper, &-linkwrapper {
      display: flex;
      align-items: center;
    }

    &-linkwrapper.copyright-mobile {
      display: none;
    }

    &-logo {
      max-width: 140px;
      filter: brightness(1.5);
    }

    &-link {
      margin: 5px 0 0 30px;
      color: $darkSeaGreen;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
    }
  }

  @media screen and (max-width: $breakpoint-xs) {
    &--bottom {
      &-linkwrapper {
        flex-direction: column;
        justify-content: center;
        margin: 0 0 30px 0;
      }
    }
  }

  @media screen and (max-width: $breakpoint-md) {

    &--socal-bar {

      .social-text {
        display: none;
      }

      .social-link {
        font-size: 20px;
      }
    }

    &--top {
      flex-direction: column;
      padding: 30px;

      &-left {
        width: 100%;
        padding: 0 0 20px 0;
      }

      &-formtitle {
        font-size: 25px;
        line-height: 30px;
      }

      &-formbutton.button {
        padding: 12px 30px;
      }

      &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 15px 0;
        padding: 15px 0;
        cursor: pointer;

        &::after {
          content: '\f106';
          color: $white;
          font-size: 14px;
          font-family: $fontAwesome;
          transition: $transition-slow;
        }
      }

      &-list {
        overflow: hidden;
        width: 100%;
        max-height: 300px;
        padding: 0;
        transition: $transition-slow;

        &.collapsed {
          max-height: 55px;

          .main-footer--top-title::after {
            transform: rotate(180deg);
          }
        }
      }
    }

    &--logos {
      display: none;
    }

    &--bottom {
      flex-direction: column;
      padding: 30px;

      &-socialwrapper, &-linkwrapper {
        justify-content: center;
        margin: 0 0 30px 0;
      }

      &-linkwrapper {
        justify-content: space-evenly;

        &.copyright-mobile {
          display: block;
          text-align: center;
        }
      }

      &-link {
        margin-bottom: 10px;
        margin-left: 0;

        &.copyright-desktop {
          display: none;
        }
      }
    }
  }
}
</style>
